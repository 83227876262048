<template>
  <div class="pitc_form__field">
    <label
      :for="fieldId"
      class="form__label hidden"
    >{{ fieldLabel }}</label>
    <div class="form__controls">
      <input
        :id="fieldId"
        v-model="model"
        :type="fieldType"
        class="field"
        :name="fieldId"
        :placeholder="fieldLabel"
      >
    </div><!-- /.form__controls -->
  </div>
</template>

<script setup>

const model = defineModel({type: String, required: true});
defineProps({
  fieldId: {
    type: String,
    required: true,
  },
  fieldLabel: {
    type: String,
    required: true,
  },
  fieldType: {
    type: String,
    required: true,
  },
});
</script>
