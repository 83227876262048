<template>
<div class="intro__body">
  <div class="intro__content">
    <h1 class="intro__title">{{ title }}</h1>
    <div class="intro__logo">
      <a href="#">
        <img :src="logo" :srcset="srcset" width="220" height="48" alt="">
      </a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'intro-content',
  props: {
    title: {
      type: String,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
    srcset: {
      type: String,
      required: true,
    },
  },
};
</script>
