import http from './fetch';

export default {
  getGames(apiGameBaseURL, destinationUUID, state) {
    return new Promise((resolve, reject) => {
      const url = `${apiGameBaseURL}/gameapi/v1/parties?destination_id=${destinationUUID}&status=${state}`;
      http
        .getJSON(url, {}, '')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getGameById(apiGameBaseURL, gameId) {
    return new Promise((resolve, reject) => {
      const url = `${apiGameBaseURL}/gameapi/v1/parties/${gameId}`;
      http
        .getJSON(url, {}, '')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
